const RoutesHelper = (allowedLevels: string[]) => {
    const currentUser = JSON.parse(localStorage.getItem('current_user') || '');
    const currentUserSecondaryWeLevel = currentUser?.secondary_job_position?.map((item: any) => item?.we_level);
    return allowedLevels?.some(allowedElement => currentUser?.roles?.includes(allowedElement) || currentUserSecondaryWeLevel.includes(allowedElement)) || allowedLevels?.includes(currentUser?.job_position?.we_level);
}

const CheckTeamLeaderOrTeamCoordinator = (allowedLevels: string[]) => {
    const currentUser = JSON.parse(localStorage.getItem('current_user') || '');
    const currentUserSecondaryWeLevel = currentUser?.secondary_job_position?.map((item: any) => item?.we_level);
    const weLevels = [...currentUserSecondaryWeLevel, currentUser?.job_position?.we_level];
    const specificJobPositions = ['Koordinator tima za kontrolu kvaliteta procesa', 'Koordinator tima za proizvodnu logistiku',
        'Koordinator tima za ulaz robe', 'Koordinator tima u lageru otpreme','Voditelj tima za procesnu tehniku', 'Voditelj tima u proizvodnji', 'Voditelj tima za elektro i mašinsko održavanje'];

    if(allowedLevels?.includes(getTheBiggestWeLevel(weLevels))) {
        return currentUser?.secondary_job_position.length > 0 || specificJobPositions.includes(currentUser?.job_position?.name);
    }

    return false;
}

const getTheBiggestWeLevel = (levels: string[]): string => {
    return levels.reduce((max, level) => (level < max ? level : max), levels[0]);
  };

export {RoutesHelper, CheckTeamLeaderOrTeamCoordinator}